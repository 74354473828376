import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import About from '../views/about.vue'
import User from '../views/user/user.vue'
import Login from '../views/login/login.vue'
import LoginWechat from '../views/login/wechat.vue'
import LoginAlipay from '../views/login/alipay.vue'
import LoginMpay from '../views/login/mpay.vue'
import Confirm from '../views/confirm/confirm.vue'
import Wallet from '../views/wallet/wallet.vue'
import Result from '../views/result/result.vue'
import Order from '../views/order/order.vue'
import Setting from '../views/setting/setting.vue'
import LoginBorrow from '../views/loginborrow/loginborrow.vue'
import Help from '../views/help/help.vue'
import WalletIn from '../views/wallet/wallet_in.vue'
import WalletOut from '../views/wallet/wallet_out.vue'
import WechatGuide from '../views/wechat_guide.vue'
import Join from '../views/join/join'
import Borrow from '../views/borrow/borrow.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_wechat',
    name: 'login_wechat',
    component: LoginWechat,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_alipay',
    name: 'login_alipay',
    component: LoginAlipay,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_mpay',
    name: 'login_mpay',
    component: LoginMpay,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/loginborrow',
    name: 'loginborrow',
    component: LoginBorrow,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/wallet_in',
    name: 'wallet_in',
    component: WalletIn
  },
  {
    path: '/wallet_out',
    name: 'wallet_out',
    component: WalletOut
  },
  {
    path: '/wechat_guide',
    name: 'wechat_guide',
    component: WechatGuide,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/borrow',
    name: 'borrow',
    component: Borrow
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.query.AppleIdToken) {
    store.commit('appleIdToken', to.query.AppleIdToken)
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/loginborrow'
    })
  }
})

export default router
