<template>
  <div class="content">
    <div class="home-head">
      <img class="home-logo" src="../assets/imgs/logo.png" alt="" />
      <span class="lang" @click="showLangSelect">{{ locale && locale.label }}</span>
      <!-- <div class="home-head-title">
        <p class="home-head-title-small">Welcome to</p>
        <p class="home-head-title-big">Trinity St Kilda</p>
      </div> -->
    </div>
    <div class="home-body">
      <div class="home-body-main">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#232360">
          <!-- <van-swipe-item>
            <img class="imgmain" src="../assets/imgs/step1.png" alt="">
            <h2>{{ i18n.locate }} <span>{{ i18n.battGo }}</span> {{ i18n.station }}</h2>
            <p>{{ i18n.step1 }}</p>
          </van-swipe-item> -->
          <van-swipe-item>
            <img class="imgmain" src="../assets/imgs/step2.png" alt="">
            <h2><span>{{ i18n.scan }}</span> {{ i18n.the }}  <span>{{ i18n.QR }}</span> {{ i18n.code }}</h2>
            <p>{{ i18n.step2 }}</p>
          </van-swipe-item>
          <van-swipe-item>
            <img class="imgmain" src="../assets/imgs/step3.png" alt="">
            <h2><span>{{ i18n.charge }}</span> {{ i18n.onthego }}.</h2>
            <p>{{ i18n.step3 }}</p>
          </van-swipe-item>
          <van-swipe-item>
            <img class="imgmain" src="../assets/imgs/step4.png" alt="">
            <h2>{{ i18n.return }} <span>{{ i18n.anywhere }}.</span></h2>
            <p>{{ i18n.step4 }}</p>
          </van-swipe-item>
        </van-swipe>
        <div class="home-body-main-bottom">
          <template v-if="!code">
            <a v-if="system === 'ios'" style="background: #000;" @click="onAppStore">
              <img src="../assets/imgs/apple.png" alt="">
              <span>App Store</span>
            </a>
            <a v-else style="background: #000;" @click="onGooglePlay">
              <img src="../assets/imgs/google.png" alt="">
              <span>Google Play</span>
            </a>
          </template>
          <template v-else>
            <div class="icondark">
              <img src="../assets/imgs/appstore_dark.png" @click="onAppStore" alt="">
              <img src="../assets/imgs/googleplay_dark.png" @click="onGooglePlay" alt="">
            </div>
            <div v-if="qrcode" class="next" @click="goRouter(`/loginborrow?qrcode=${qrcode}`)">
              <div class="text">NEXT</div>
              <img src="../assets/imgs/icon_next_circle.png" alt="">
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant'
import { mapState, mapGetters } from 'vuex'
import { QR_CODE, ZH_CODE } from '../apis/cabinet'
import { CREATE_VIP_ORDER, BALANCE_TO_RENT } from '../apis/payment'
import { USER_INFO, OEDER_COUNT } from '../apis/user'
export default {
  components: {
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem
  },
  watch: {
  },
  computed: {
    ...mapState([
      'qrcode',
      'zhcode',
      'cabinet',
      'browser',
      'user',
      'system',
      'lang',
      'headLogo'
    ]),
    ...mapGetters(['locale']),
    i18n () {
      return this.$t('home')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    title () {
      return process.env.VUE_APP_NAME
    },
    code () {
      return this.qrcode || this.zhcode
    },
    scanUrl () {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ''
    },
    shopDesc () {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    amount () {
      if (this.cabinet) {
        return this.cabinet.yajin
        // return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    },
    isView () {
      return this.isScan && this.cabinet && !this.isBtns
    }
  },
  data () {
    return {
      isScan: false,
      isBtns: false,
      inputCodeShow: false,
      mainHeight: 400,
      ua: navigator.userAgent.toLowerCase(),
      markerList: [],
      oldMarkerList: [],
      cabinetCode: '',
      latitude: '113.788876', // 113.788876
      longitude: '22.703407', // 22.703407
      pay: process.env.VUE_APP_PAY === '1',
      userAgreement: false,
      privacyAgreement: false
      // payTypeShow: false,
      // payTypeValue: 1
    }
  },
  methods: {
    showLangSelect () {
      this.$store.commit('isLangView', true)
    },
    goRouter (path) {
      this.$router.push(path)
    },
    onDownload () {
      if (this.system === 'ios') {
        window.open(process.env.VUE_APP_APP_STORE)
      } else {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
    },
    onAppStore () {
      console.log('11111')
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay () {
      console.log('22222')
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    },
    onBack () {
      this.isScan = false
    },
    codeShowClose () {
      this.inputCodeShow = false
      this.cabinetCode = ''
    },
    onScan (isclick) {
      // if (!this.scanUrl && !isclick) return
      let url = this.scanUrl
      if (isclick && this.cabinetCode) {
        url = `${QR_CODE}?id=${this.cabinetCode}`
        this.$store.commit('qrcode', this.cabinetCode)
      } else if (!this.scanUrl && !isclick) {
        return
      }
      this.inputCodeShow = false
      this.cabinetCode = ''
      this.$loading(true)
      this.$post(
        url,
        {},
        resp => {
          this.$loading(false)
          this.$store.commit('cabinet', {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              // cancelText: this.i18nMsg.cancel,
              showCancel: false
            }).then(() => {
              // this.$router.push('/order')
              this.$store.commit('qrcode', '')
            }).catch(() => {
              this.$store.commit('qrcode', '')
            })
          } else {
            this.isScan = true
          }
        },
        error => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18n.confirm,
            content: error,
            showCancel: false
          }).then(() => {
            this.$store.commit('qrcode', '')
          })
          // this.$toast(error)
        }
      )
    },
    onSubmit () {
      if (!this.userAgreement || !this.privacyAgreement) {
        this.$toast('Please review and agree to the relevant agreement below')
        return
      }
      this.$router.push({ path: '/confirm', query: { price: this.amount, payType: 'YJ', cabinetID: this.cabinet.cabinetID } })
    },
    onBorrow () {
      this.$loading(true)
      this.$post(
        `${BALANCE_TO_RENT}?qrcode=${this.cabinet.qrcode}`,
        {},
        resp => {
          this.$loading(false)
          console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        error => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onVIP () {
      this.$loading(true)
      this.$post(
        `${CREATE_VIP_ORDER}/${this.cabinet.qrcode}`,
        {},
        resp => {
          this.$loading(false)
          // console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        error => {
          this.$loading(false)
          // console.log(error)
          this.$toast(error)
        }
      )
    },
    loadUser () {
      this.$get(
        USER_INFO,
        {},
        resp => {
          if (resp.user) {
            this.$store.commit('user', resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              // confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        error => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            showOk: false,
            // confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    onCode () {
      if (this.user) {
        this.isBtns = false
        this.loadUser()
        this.onScan()
      } else {
        this.$router.push('/login')
      }
    },
    closeTouch () {
      let node = document.getElementsByTagName('body')[0]
      node.style.overflow = 'hidden'
      document
        .getElementsByTagName('body')[0]
        .addEventListener('touchmove', this.handler, { passive: false }) // 阻止默认事件
    },
    openTouch () {
      let node = document.getElementsByTagName('body')[0]
      node.style.overflow = 'auto'
      document
        .getElementsByTagName('body')[0]
        .removeEventListener('touchmove', this.handler, { passive: false }) // 打开默认事件
    },
    handler (e) {
      e.preventDefault()
    },
    getOrderCount () {
      this.$get(
        OEDER_COUNT,
        {},
        resp => {
          if (resp.noMoney) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.unpaid_order,
              confirmText: this.i18nMsg.go_pay,
              showCancel: false
            }).then(() => {
              this.$router.push('/order')
            })
          }
        },
        error => {
          console.log(error)
        }
      )
    },
    // 頭部主頁圖標按鈕
    goHome () {
      this.$store.commit('qrcode', '')
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  created () {
    let inviteCode = this.$route.query.inviteCode || this.$route.query.inviterCode
    if (inviteCode) {
      this.$store.commit('inviteCode', inviteCode)
      this.$store.commit('user', null)
      this.$store.commit('token', '')
      this.$router.push('/loginborrow')
    }
    // console.log('url: ', window.location.href)
    // let qrCode = this.qrcode || this.$route.query.qrcode
    // console.log('qrCode', qrCode)
    // if (qrCode) {
    //   this.$store.commit('qrcode', qrCode)
    //   if (this.user) {
    //     this.onCode()
    //   }
    // }
    // if (this.user) {
    //   this.getOrderCount()
    // }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.lang{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after{
    content: '';
    margin-left: 10px;
    width: 0;
    border: 10px solid;
    border-color: #000 transparent transparent transparent;
  }
}
.content{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
/deep/ .my-swipe .van-swipe__indicators{
  bottom: 0;
}
.home-head{
  width: 100%;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin: 20px 0 0;
  padding: 46px 60px;
  .home-logo{
    width: 208px;
  }
  &-title{
    p{
      text-align: end;
      margin: 10px 0;
    }
    &-small{
      font-family: 'Montserrat-Medium';
      font-size: 24px;
      color: #171917;
    }
    &-big{
      font-family: 'Montserrat-SemiBold';
      font-size: 36px;
      font-weight: 500;
      color: #000;
    }
  }
}
.home-body{
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // height: calc(100vh - 220px);
  flex: 1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  .home-body-main{
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px 60px;
    box-sizing: border-box;
    height: 100%;
    .imgmain{
      width: 90%;
      display: block;
      margin: 0 auto 20px;
    }
    h2{
      margin: 10px 0;
    }
    p{
      margin: 10px 0;
    }
    &-bottom{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // position: relative;
      margin: 120px auto 0;
      &>a {
        text-decoration: none;
        width: 30%;
        flex: 1;
        margin: 100px 0 20px;
        background: #000000;
        color: #ffffff;
        padding: 20px 0;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      .icondark{
        width: 45%;
        img{
          display: block;
          width: 100%;
        }
        img:last-child{
          margin-top: 20px;
        }
      }
      .next{
        font-family: 'DIN Condensed Bold';
        font-size: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        background: #232360;
        border-radius: 20px;
        padding: 20px 20px;
        margin-bottom: 26px;
        .text {
          height: 54px;
          // line-height: 64px;
          display: block;
        }
        img{
          width: 54px;
          height: 54px;
          margin-left: 20px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
