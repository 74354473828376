
import md5 from 'js-md5'

import {
  LOGIN_CODE,
  SEND_CODE,
  USER_INFO
} from '../apis/user'

export default {
  methods: {
    // 发送验证码
    async onSendCode (phoneNum) {
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, '8')
      let mdsign = md5(str + 'GZzbjkj')
      this.$loading(true)
      const query = {
        mobile: this.areaCode + phoneNum,
        timestamp: timp,
        sign: mdsign,
        userEnd: 'h5'
      }
      this.$post(
        SEND_CODE,
        query,
        (res) => {
          console.log(res)
          this.$loading(false)
          this.onLogin('1234')
          this.$store.commit('phone', phoneNum)
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
      return true
    },
    // 登录
    onLogin (code) {
      this.$loading(true)
      this.$post(
        LOGIN_CODE,
        {
          mobile: this.areaCode + this.phone,
          verifyCode: code,
          areaCode: this.areaCode,
          registAndLoginType: 1
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit('token', res.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        }
      )
    },
    // 获取用户信息
    loadUser () {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (res) => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit('user', res.user)
            this.$toast(this.i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit('token', null)
              })
          }
        },
        (err) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit('token', null)
          })
        }
      )
    }
  }
}
