<template>
  <page>
    <div class="home-body">
      <div class="language">
        <span class="language-label" @click="showLangSelect">{{
          locale && locale.label
        }}</span>
        <span class="language-tip">LANGUAGE</span>
      </div>
      <div v-if="!user" class="title">{{ i18n.powerbanktitle }}</div>
      <!-- <Apple v-if="system === 'ios' && !user" @success="loadUser(true)">
        <button class="loginbtn" id="sign-in-with-apple-button">
          <img src="../../assets/imgs/icon_applepay.png" alt="">
          <span>{{ i18n.Apple }}</span>
        </button>
      </Apple>
      <Facebook v-else-if="system === 'android' && !user" @success="loadUser(true)">
        <div class="facebookbtn">
          <img src="../../assets/imgs/icon_facebook.png" alt="">
          <span>{{ i18n.loginfb }}</span>
        </div>
      </Facebook> -->
      <!-- <Google @success="loadUser(true)">
        <div id="buttonDiv" class="buttonDiv"></div>
        <div class="googlewarp">
          <img src="../../assets/imgs/icon_google.png" alt="">
          <span>{{ i18n.logingg }}</span>
        </div>
      </Google> -->
      <button class="loginbtn">
        <img
          :src="
            loginMode === 'Google'
              ? require('../../assets/imgs/icon_googlepay.png')
              : loginMode === 'Apple'
              ? require('../../assets/imgs/icon_applepay.png')
              : loginMode === 'Facebook'
              ? require('../../assets/imgs/icon_facebook_unselected.png')
              : require('../../assets/imgs/icon_phone_selected.png')
          "
          alt=""
        />
        <span>{{ user && user.pname }}</span>
      </button>
      <!-- <div class="options" v-if="!user" @click="loginOptionShow = true">{{ i18n.otheropt }}</div> -->
      <!-- <div class="options" v-else @click="onLogout">{{ i18n.logout }}</div> -->
      <!-- <div class="normaltitle">{{ i18n.selectmethod }}</div>
      <div v-if="payModeList.length > 0" class="paymethod">
        <template v-for="item in payModeList">
          <div class="paymethod-item" :class="{ 'select': payMode && payMode.pPaymentType === item.pPaymentType }" v-if="(item.phone === 'all' || item.phone === system || item.phone === browser || (item.phone === 'phone' && system !== ''))" :key="item.pId" @click="payMode = item">
            <img :src="item.pPaymentType === 'GOOGLE_PAY' || item.pPaymentType === 'STRIPE_GOOGLE' ? require('../../assets/imgs/icon_googlepay.png') : item.pPaymentType === 'APPLE_PAY' || item.pPaymentType === 'STRIPE_APPLE' ? require('../../assets/imgs/icon_applepay.png') : require('../../assets/imgs/icon_card_Unselected.png')" alt="">
            <span>{{item.label}}</span>
          </div>
        </template>
      </div> -->
      <!-- <div v-else-if="!user || !cabinet" class="paymethod">
        <div class="paymethod-item" :class="{ 'select': payMode && payMode.pPaymentType === 'STRIPE_CARD' }" @click="payMode = { pPaymentType: 'STRIPE_CARD' }">
          <img src="../../assets/imgs/icon_card_Unselected.png" alt="">
          <span>CREDIT CARD</span>
        </div>
      </div> -->
      <div class="normaltitle">{{ i18n.paymentdetail }}</div>
      <div class="paymethod-detail">
        <!-- <div v-if="payMode && payMode.pPaymentType === 'STRIPE_CARD'" class="addcard" @click="payListShow = true">
          <div class="left">
            <img src="../../assets/imgs/icon_couponcode.png" alt="">
            <span class="addcard-msg">{{ i18n.addcard }}</span>
          </div>
          <div class="right">
            <span>{{ cardMode && cardMode.pCardnumber }}</span>
            <img src="../../assets/imgs/icon_more.png" alt="">
          </div>
        </div> -->
        <div class="placeinput">
          <img class="preicon" src="../../assets/imgs/icon_pay.png" alt="" />
          <input
            type="text"
            readonly
            :placeholder="`Pre-authorisation ${currency}${
              (cabinet && cabinet.yajin) || 20
            }`"
          />
          <img
            class="afticon"
            src="../../assets/imgs/icon_detail_green.png"
            alt=""
            @click="preAuthorShow = true"
          />
        </div>
        <!-- <div v-if="(payMode && payMode.pPaymentType === 'STRIPE_CARD' && !token) || !cabinet" class="placeinput mt-4">
          <img class="preicon" src="../../assets/imgs/icon_card_Unselected.png" alt="">
          <input type="text" v-model="cardNum" placeholder="Card number">
          <span class="cvc">MM / YY CVC</span>
        </div>
        <div v-if="payMode && payMode.pPaymentType === 'STRIPE_CARD' && token && cabinet" class="placeinput mt-4">
          <div class="w-full" id="credit-card-element"></div>
        </div> -->
      </div>
      <div class="shopdetail">
        <img src="../../assets/imgs/img_charge_pricing.png" alt="" />
        <div v-if="cabinet" class="centermsg">
          <p class="top">
            {{ currency
            }}{{
              cabinet &&
              cabinet.priceStrategy &&
              cabinet.priceStrategy.pPrice.toFixed(2)
            }}
            per {{ cabinet && cabinet.priceStrategy.pPriceMinute }}
            {{ priceUnit }}
          </p>
          <!-- <p class="top">AU ${{ cabinet.jifei }} per {{ cabinet.jifeiDanwei }} min</p> -->
          <p class="bottom">
            {{ currency }}{{ cabinet.fengding }} max. per day
          </p>
        </div>
        <div v-else class="centermsg">
          <p class="top">{{ currency }}1.0 per hour</p>
          <p class="bottom">{{ currency }}6 max. per day</p>
        </div>
        <div class="pricing" @click="showRentCostPop">
          {{ i18n.pricing }} <img src="../../assets/imgs/icon_morearrow.png" />
        </div>
      </div>
      <div class="normaltitle">{{ i18n.couponcode }}</div>
      <div class="coupon-code">
        <div class="placeinput">
          <img
            class="preicon"
            src="../../assets/imgs/icon_couponcode.png"
            alt=""
          />
          <input type="text" v-model="couponCode" placeholder="i.e.:FREE60" />
        </div>
      </div>
      <div class="rentbtn" @click="onRent">{{ i18n.rent }}</div>
      <div class="agreen">
        {{ i18n.tips }}
        <a
          @click="
            viewAgreen('https://m.we-charge.ch/content/privacy_policy.html')
          "
          >{{ i18n.privacy }}</a
        >
        {{ i18n.and }}
        <a
          @click="
            viewAgreen('https://m.we-charge.ch/content/user_agreement.html')
          "
          >{{ i18n.term }}</a
        >
      </div>
    </div>
    <div v-if="loginOptionShow" class="mask" @click="loginOptionShow = false">
      <div class="mask-main" @click.stop>
        <div class="appleline"></div>
        <!-- <div class="loginoptions">
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'phone' }" @click="currentLoginMode = 'phone'">
            <img src="../../assets/imgs/icon_phone_selected.png" alt="">
            <span>{{ i18n.phone }}</span>
          </div>
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'Community' }" @click="currentLoginMode = 'Community'">
            <span>{{ i18n.otheropt }}</span>
          </div>
        </div> -->
        <div class="phonelogin">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phone"
            :placeholder="i18n.phonenumber"
          />
          <img
            src="../../assets/imgs/icon_next_circle.png"
            alt=""
            @click="onSendCode"
          />
        </div>
        <!-- <div class="phonelogin">
          <input type="number" v-model="code" :placeholder="i18n.captcha_code">
          <img class="captcha" :src="captchaUrl" alt="" @click="onCaptcha">
        </div> -->
        <!-- <Facebook v-if="system !== 'android'" @success="loadUser(true)">
          <div class="facebookbtn">
            <img src="../../assets/imgs/icon_facebook.png" alt="">
            <span>{{ i18n.loginfb }}</span>
          </div>
        </Facebook>
        <Google @success="loadUser(true)" class="mb-8 minheight-5">
          <div class="buttonDiv" id="buttonDiv"></div>
          <div class="googlewarp">
            <img src="../../assets/imgs/icon_google.png" alt="">
            <span>{{ i18n.logingg }}</span>
          </div>
        </Google> -->
        <div class="agreen">
          {{ i18n.tips }}
          <a
            @click="
              viewAgreen('https://m.we-charge.ch/content/privacy_policy.html')
            "
            >{{ i18n.privacy }}</a
          >
          {{ i18n.and }}
          <a
            @click="
              viewAgreen('https://m.we-charge.ch/content/user_agreement.html')
            "
            >{{ i18n.term }}</a
          >
        </div>
        <!-- <Apple v-if="currentLoginMode === 'Apple'" @success="loadUser(true)" class="mb-8">
          <button class="loginbtn">
            <img src="../../assets/imgs/icon_applepay.png" alt="">
            <span>{{ i18n.Apple }}</span>
          </button>
        </Apple> -->
      </div>
    </div>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onStripeSetup">
          {{ i18n.savenow }}
        </div>
      </div>
    </popup-warp>
    <popup-warp v-model="payListShow">
      <div class="addsign" @click="saveCard">
        <span>+</span>
      </div>
      <div class="scroll-x">
        <div
          class="popup-card-list"
          :class="{ selectd: cardMode && cardMode.pId === item.pId }"
          v-for="item in cardList"
          :key="item.pId"
        >
          <p class="value">
            <span class="pBrand">{{ item.pBrand }}</span>
            <span>{{ item.pCardnumber }}</span>
          </p>
          <img
            class="deleteicon"
            @click="removeCardConfirm(item.pId)"
            src="../../assets/imgs/icon_subtraction.png"
            alt=""
          />
        </div>
      </div>
    </popup-warp>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <PreAuthorTip
      v-if="preAuthorShow"
      @close="(res) => (preAuthorShow = res)"
    ></PreAuthorTip>
    <RentCostTip
      v-if="rentCostShow"
      :jifei="(cabinet && cabinet.jifei) || 1.0"
      :jifeiDanwei="(cabinet && cabinet.jifeiDanwei) || 60"
      :priceUnit="priceUnit"
      :fengding="(cabinet && cabinet.fengding) || 6"
      :yajin="20"
      :currencyName="currencyName"
      :currency="currency"
      @close="(res) => (rentCostShow = res)"
    ></RentCostTip>
    <LoginCode
      v-if="loginCodeShow"
      @close="(res) => (loginCodeShow = res)"
      @submit="onLogin"
    ></LoginCode>
  </page>
</template>

<script>
// import md5 from 'js-md5'
import { mapState, mapGetters } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import datatransMixin from "../../mixins/datatrans"
import userMixin from "../../mixins/user"
import { generateUUID, getMoble } from "../../utils/index"
import { baseURL } from "../../utils/http"
import { SelectArea } from "../../components/select"
import PreAuthorTip from "./component/preauthortip.vue"
import RentCostTip from "./component/rentcosttip.vue"
import LoginCode from "./component/logincode.vue"
// import Google from '../../components/login/google.vue'
// import Facebook from '../../components/login/facebook.vue'
// import Apple from '../../components/login/apple.vue'
import { PopupWarp } from "../../components/popup"
import { USER_CAPTCHA } from "../../apis/user"
import { QR_CODE, ZH_CODE } from "../../apis/cabinet"
import {
  PAYMODE,
  USER_CARD,
  STRIPE_SAVE_CARD,
  DATATRANS_PREAUTH,
  STRIPE_SAVE_CARD_CONFIRM,
  REMOVE_CARD,
  STRIPE_PREPAY_SPTOKEN,
  COUPON_SEND
} from "../../apis/payment"
export default {
  mixins: [datatransMixin, userMixin],
  components: {
    SelectArea,
    PreAuthorTip,
    RentCostTip,
    LoginCode,
    // Google,
    // Facebook,
    // Apple,
    PopupWarp
  },
  watch: {
    "payMode.pPaymentType": {
      handler(val, oldVal) {
        console.log("payMode", val)
        if (
          val === "APPLE_PAY" ||
          val === "STRIPE_APPLE" ||
          val === "STRIPE_GOOGLE" ||
          val === "GOOGLE_PAY"
        ) {
          if (!this.stripePayRequest && this.cabinet) {
            this.$loading(true)
            this.getStripeToken()
          }
        } else if (val === "STRIPE_CARD" && this.cabinet && this.token) {
          this.getCardList()
        }
      },
      immediate: true, // 立即执行
      deep: true // 深度监听复杂类型内变化
    },
    cabinet(val) {
      if (val) {
        console.log("设备信息", val)
        this.getPayMode()
      }
    },
    user(val, oldVal) {
      if (oldVal == null && val) {
        this.onCode()
      }
    }
  },
  computed: {
    ...mapState([
      "browser",
      "user",
      "token",
      "cabinet",
      "system",
      "qrcode",
      "agentId",
      "loginMode",
      "inviteCode",
      "phone"
    ]),
    ...mapGetters(["locale"]),
    scanUrl() {
      if (this.qrcode || this.$route.query.qrcode) {
        return `${QR_CODE}?id=${this.qrcode || this.$route.query.qrcode}`
      }
      if (this.zhcode || this.$route.query.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    i18n() {
      return this.$t("loginborrow")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      return (this.cabinet && this.cabinet.currency) || "CHF"
    },
    currencyName() {
      return (this.cabinet && this.cabinet.currencyName) || "₣"
    },
    priceUnitMap() {
      return {
        0: "分钟",
        1: "小时",
        2: "天"
      }
    },
    priceUnit() {
      return this.cabinet && this.cabinet.priceStrategy.priceUnit
        ? this.$t(
            `home.${this.priceUnitMap[this.cabinet.priceStrategy.priceUnit]}`
          )
        : this.$t("home.minute")
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "41",
      captchaUrl: "",
      code: "",
      uuid: "",
      cardNum: "",
      couponCode: "",
      preAuthorShow: false,
      loginOptionShow: false,
      rentCostShow: false,
      loginCodeShow: false,
      isCreditcard: false,
      payListShow: false,
      currentLoginMode: "phone",
      payModeList: [],
      cardList: [],
      cardMode: null,
      payMode: {
        pPaymentType: "STRIPE_CARD"
      },
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      stripePayRequest: null,
      creditcard: null,
      clientSecret: null,
      creditOrder: null
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    onSelct(val) {
      this.areaCode = val
    },
    showRentCostPop() {
      this.rentCostShow = true
    },
    viewAgreen(url) {
      window.open(url)
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    onLogout() {
      console.log("退出==")
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.msg_logout,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.$store.commit("user", "")
        this.$store.commit("token", "")
        this.$store.commit("appleIdToken", "")
        // this.$store.commit('qrcode', '')
        this.payModeList = []
        this.cardList = []
        this.cardMode = null
      })
    },
    onRent() {
      console.log(window.datatrans)
      if (this.user) {
        if (
          this.cabinet &&
          this.cabinet.zujieNum &&
          this.cabinet.zujieNum > 0
        ) {
          this.$toast(this.i18nMsg.exceedorder)
          return
        }
        if (!this.qrcode && !this.$route.query.qrcode) {
          this.$toast(this.i18nMsg.none_code)
          return
        }
        this.$loading(true)
        this.$post(
          `${DATATRANS_PREAUTH}`,
          {
            paymentId: this.payMode.pId,
            qrcode: this.qrcode || this.$route.query.qrcode,
            userEnd: "web"
          },
          (resp) => {
            this.$loading(false)
            console.log(resp)
            this.creditOrder = resp.data
            if (this.couponCode) {
              this.getCouponCode()
              return
            }
            this.onPay()
          },
          (error) => {
            this.$loading(false)
            this.$toast(error)
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          }
        )
      } else {
        this.$toast(this.i18nMsg.unlogin)
      }
    },
    onPay() {
      if (
        this.payMode.pPaymentType === "APPLE_PAY" ||
        this.payMode.pPaymentType === "STRIPE_APPLE" ||
        this.payMode.pPaymentType === "STRIPE_GOOGLE" ||
        this.payMode.pPaymentType === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
      } else if (this.payMode.pPaymentType === "datatrans-web") {
        this.onCreditcard()
      }
    },
    onScan() {
      let url = this.scanUrl
      this.inputCodeShow = false
      this.$loading(true)
      this.$post(
        url,
        {
          usePriceUnit: true
        },
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0) {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: `${this.i18n.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.confirm,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          }
        },
        (error) => {
          this.$loading(false)
          let errstr = ""
          if (error.includes("is offline") || error.includes("离线")) {
            errstr = this.$t("home.offline", { devicecode: this.qrcode })
          } else if (error.includes("Permission") || error.includes("权限")) {
            errstr = this.$t("home.permissionfail")
          } else if (error.includes("not bound") || error.includes("未绑定")) {
            errstr = this.$t("home.unbound", { devicecode: this.qrcode })
          } else if (error.includes("No battery") || error.includes("可用")) {
            errstr = this.$t("home.nobattery", { devicecode: this.qrcode })
          } else {
            errstr = error
          }
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      )
    },
    getStripeToken() {
      getToken(this.cabinet && this.cabinet.yajin, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet) {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.payMode.pId // 代理或平台支付配置id
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    onCreditcard() {
      console.log("开始支付")
      window.datatrans.startPayment({
        transactionId: this.creditOrder.trade_no
      })
    },
    getCouponCode() {
      this.$loading(true)
      this.$get(
        `${COUPON_SEND}?code=${this.couponCode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.onPay()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.onPay()
        }
      )
    },
    getCardList() {
      this.$loading(true)
      this.$get(
        `${USER_CARD}?status=1&agentId=${this.agentId}&userEnd=web`,
        {},
        (resp) => {
          this.$loading(false)
          console.log("cardlist", resp)
          this.cardList = resp.data
          if (resp.data && resp.data.length > 0) {
            this.cardMode = resp.data[0]
          } else {
            this.cardMode = null
          }
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    getPayMode() {
      this.$loading(true)
      this.$get(
        `${PAYMODE}?id=${this.cabinet.cabinetID}`,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            let list = resp.data
            list.filter((v, i, arr) => {
              if (v.pPaymentType === "datatrans-web") {
                v.label = "Credit Card"
                v.phone = "all"
              }
              return v.pPaymentType === "datatrans-web"
              // if (v.pPaymentType === 'datatrans-web') {
              //   v.label = 'Credit Card'
              //   v.phone = 'all'
              // } else if (v.pPaymentType === 'STRIPE_WECHAT') {
              //   v.label = 'WeChat'
              //   v.phone = 'wechat'
              // }
            })
            this.payModeList = list
            if (list.length > 0) {
              this.payMode = list[0]
              this.loadDataTransScript()
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_paymod,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.getPayMode()
          })
          console.log(error)
        }
      )
    },
    saveCard() {
      console.log("this.payMode", this.payMode)
      if (!this.payMode || !this.payMode.pId || !this.user) {
        this.$toast(this.i18nMsg.unlogin)
        return
      }
      let url = `${STRIPE_SAVE_CARD}?paymentId=${this.payMode.pId}`
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.creditOrder = resp.data
          this.onLoadElement()
          console.log("saveCard====", resp)
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.saveCard()
          })
          console.log(error)
        }
      )
    },
    onLoadElement() {
      const options = {
        clientSecret: this.creditOrder.clientSecret,
        appearance: {
          theme: "stripe"
        }
      }
      if (window.stripe) {
        // this.loadDataTransScript()
        this.creditcard = window.stripe.elements(options)
        const paymentElement = this.creditcard.create("payment")
        this.isCreditcard = true
        this.payListShow = false
        paymentElement.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
        this.loadDataTransScript()
        setTimeout(() => {
          this.onLoadElement()
        }, 500)
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      let pid = this.payMode.pId
      let url = this.qrcode
        ? `${window.location.origin}#/loginborrow?qrcode=${this.qrcode}&paymentId=${pid}&pmid=${this.creditOrder.paymentId}`
        : `${window.location.origin}#/loginborrow?paymentId=${pid}&pmid=${this.creditOrder.paymentId}`
      window.stripe
        .confirmSetup({
          elements: this.creditcard,
          confirmParams: {
            return_url: url
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          } else {
            console.log("成功====", result)
          }
        })
    },
    onConfirmSaveCard(pid, id) {
      this.$loading(true)
      this.$post(
        STRIPE_SAVE_CARD_CONFIRM,
        {
          paymentId: pid,
          paymentMethod: id
        },
        (resp) => {
          this.$loading(false)
          console.log("saveCardConfirm====", resp)
          this.getCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.onConfirmSaveCard(pid, id)
          })
          console.log(error)
        }
      )
    },
    removeCardConfirm(id) {
      this.$confirm({
        title: this.i18nMsg.msg_fail_title,
        content: this.i18nMsg.deletecard,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.removeCard(id)
      })
    },
    removeCard(id) {
      this.$loading(true)
      this.$get(
        `${REMOVE_CARD}/${id}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log("removecard====", resp)
          this.payListShow = false
          this.getCardList()
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18nMsg.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.removeCard(id)
          })
          console.log(error)
        }
      )
    },
    onCode() {
      console.log(this.qrcode)
      if (this.token) {
        this.loadUser()
      }
      if ((this.qrcode || this.$route.query.qrcode) && this.token) {
        this.onScan()
      }
    }
  },
  async created() {
    if (!this.user) {
      if (!this.phone) {
        this.$store.commit("phone", getMoble(6))
        await this.onSendCode(this.phoneNum || this.phone)
      } else {
        await this.onSendCode(this.phoneNum || this.phone)
      }
    }
    // this.getPayMode()
    let qrCode = this.qrcode || this.$route.query.qrcode
    console.log("qrCode====", qrCode)
    if (qrCode) {
      this.$store.commit("qrcode", qrCode)
      if (this.user) {
        this.onCode()
        // this.getCardList()
      }
    }
    let { paymentId, pmid } = this.$route.query
    if (paymentId) {
      this.onConfirmSaveCard(paymentId, pmid)
    }
    // this.onCode()
    // this.onCaptcha()
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}
.buttonDiv {
  position: relative;
  z-index: 99;
  top: 0;
  opacity: 0;
}
.googlewarp {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.select {
  color: #fff !important;
  background-color: #232360 !important;
  border: none !important;
}
.selectd {
  border: solid 1px #232360 !important;
}
.mb-8 {
  margin-bottom: 60px;
}
.minheight-5 {
  min-height: 90px;
}
.agreen {
  color: #171917;
  text-align: center;
  margin: 40px 0 20px;
  a {
    text-decoration: none;
    font-weight: bold;
  }
}
.loginbtn {
  border: solid 1px #ebebeb;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  background: none;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.addcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  color: #757575;
  // box-shadow: 3px 6px 10px #e8e8e8;
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.home-body {
  background-color: #fff;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  overflow: hidden;
  padding: 40px;
  box-sizing: border-box;
  height: 100%;
  .language {
    position: absolute;
    top: 30px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-label {
      width: 50px;
      height: 40px;
      display: block;
      border: solid 1px #171917;
      padding: 10px;
      font-size: 30px;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
    }
    &-tip {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  .title {
    font-family: "Montserrat-SemiBold";
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin: 0 0 50px;
  }
  .options {
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    margin: 32px 0 60px;
  }
  .normaltitle {
    font-family: "Montserrat-Medium";
    font-size: 28px;
    color: #171917;
    margin: 30px 0 32px;
  }
  .paymethod {
    display: flex;
    align-items: center;
  }
  .paymethod-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin-right: 30px;
    img {
      width: 45px;
      height: 40px;
      margin-right: 10px;
    }
    span {
      color: #fff;
      font-size: 28px;
    }
  }
  .placeinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    padding: 20px;
    margin: 30px 0 0;
    .preicon {
      width: 38px;
      height: 38px;
      margin-right: 20px;
    }
    .afticon {
      width: 34px;
      height: 34px;
      margin-left: 20px;
    }
    .cvc {
      font-size: 28px;
      color: #c8c8c8;
    }
    input {
      flex: 1;
      border: none;
      outline: none;
    }
  }
  .shopdetail {
    border: dashed 4px #000;
    border-radius: 20px;
    padding: 10px;
    margin: 50px 0 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    & > img {
      width: 78px;
      height: 142px;
      position: absolute;
      left: 20px;
      top: -30px;
    }
    .centermsg {
      padding-left: 98px;
      p {
        margin: 10px 0;
      }
      .top {
        font-size: 28px;
        color: #171917;
      }
      .bottom {
        color: #666666;
        font-size: 24px;
      }
    }
    .pricing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 40px;
      color: #000;
      text-align: right;
      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
      }
    }
  }
  .rentbtn {
    width: 100%;
    background: #232360;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: "Montserrat-SemiBold";
    font-size: 36px;
    font-weight: 500;
    max-height: 108px;
    margin: 40px 0 0;
  }
  .mt-4 {
    margin-top: 40px;
  }
}
.facebookbtn {
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;
  margin-bottom: 40px;
  font-size: 28px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;
  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px;
  }
  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto 40px;
  }
  .loginoptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 0;
    &-item {
      border: solid 1px #ebebeb;
      border-radius: 20px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 25px 0 0;
      img {
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
  .phonelogin {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;
    img {
      width: 60px;
      height: 60px;
    }
    input {
      flex: 1;
      font-size: medium;
      border: none;
      outline: none;
    }
    .captcha {
      width: 180px;
    }
    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }
  .facebookbtn {
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .goolebtn {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #ebebeb;
    color: #000;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}
.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}
.creditcard-btn {
  margin: 48px;
}
.addsign {
  float: left;
  background-color: #fff;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px;
  width: 18%;
  height: 100%;
  border-radius: 20px;
  margin: 33px 0 20px 20px;
  span {
    display: block;
    background-color: #dddddd;
    color: #fff;
    font-size: 85px;
    text-align: center;
    border-radius: 20px;
  }
}
.scroll-x {
  float: right;
  width: 70%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
}
.popup-card-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px 20px;
  margin: 20px 20px 20px 0;
  p {
    margin: 0;
  }
  .value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: #00904a;
    font-weight: 500;
    .pBrand {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
  .deleteicon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
</style>
