import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    safeArea: JSON.parse(localStorage.getItem('cdb_safe_area')) || { top: 0, bottom: 0 },
    user: JSON.parse(localStorage.getItem('cdb_user')),
    userPwd: JSON.parse(localStorage.getItem('cdb_user_pwd')),
    token: localStorage.getItem('cdb_token'),
    appleIdToken: '',
    loading: false,
    isLangView: false,
    qrcode: sessionStorage.getItem('cdb_qrcode'),
    zhcode: sessionStorage.getItem('cdb_zhcode'),
    lang: localStorage.getItem('cdb_lang') || process.env.VUE_APP_I18N_LOCALE,
    system: localStorage.getItem('cdb_system') || '',
    browser: localStorage.getItem('cdb_browser') || '',
    cabinet: null,
    localeArr: [
      // { key: 0, label: 'CN', value: 'zh-CN', currency: '人民币', money: '￥' },
      { key: 1, label: 'EN', value: 'en-US', currency: 'dollar', money: '$' },
      { key: 3, label: 'DE', value: 'de-DE', currency: 'EUR', money: '€' },
      { key: 4, label: 'FR', value: 'fr-FR', currency: 'EUR', money: '€' }
    ],
    inviteCode: sessionStorage.getItem('cdb_invite_code') || '',
    coupon: null,
    area: localStorage.getItem('cdb_area') || '',
    ismobile: localStorage.getItem('cdb_ismobile') || false,
    loginMode: sessionStorage.getItem('cdb_loginmode') || 'phone',
    phone: localStorage.getItem('cdb_phone') || '',
    agentId: 'BJCD0000000000000000000000000001'
  },
  getters: {
    locale: state => {
      return state.localeArr.find(obj => obj.value === state.lang)
    }
  },
  mutations: {
    safeArea (state, payload) {
      localStorage.setItem('cdb_safe_area', JSON.stringify(payload))
      state.safeArea = payload
    },
    user (state, payload) {
      localStorage.setItem('cdb_user', JSON.stringify(payload))
      state.user = payload
    },
    userPwd (state, payload) {
      localStorage.setItem('cdb_user_pwd', JSON.stringify(payload))
      state.userPwd = payload
    },
    token (state, payload) {
      localStorage.setItem('cdb_token', payload)
      state.token = payload
    },
    appleIdToken (state, payload) {
      state.appleIdToken = payload
    },
    loading (state, payload) {
      state.loading = payload
    },
    isLangView (state, payload) {
      state.isLangView = payload
    },
    qrcode (state, payload) {
      sessionStorage.setItem('cdb_qrcode', payload)
      state.qrcode = payload
    },
    zhcode (state, payload) {
      sessionStorage.setItem('cdb_zhcode', payload)
      state.zhcode = payload
    },
    lang (state, payload) {
      localStorage.setItem('cdb_lang', payload)
      state.lang = payload
    },
    system (state, payload) {
      localStorage.setItem('cdb_system', payload)
      state.system = payload
    },
    browser (state, payload) {
      localStorage.setItem('cdb_browser', payload)
      state.browser = payload
    },
    cabinet (state, payload) {
      state.cabinet = payload
    },
    inviteCode (state, payload) {
      sessionStorage.setItem('cdb_invite_code', payload)
      state.inviteCode = payload
    },
    coupon (state, payload) {
      state.coupon = payload
    },
    area (state, payload) {
      localStorage.setItem('cdb_area', payload)
      state.area = payload
    },
    ismobile (state, payload) {
      localStorage.setItem('cdb_ismobile', payload)
      state.ismobile = payload
    },
    loginMode (state, payload) {
      sessionStorage.setItem('cdb_loginmode', payload)
      state.loginMode = payload
    },
    phone (state, payload) {
      localStorage.setItem('cdb_phone', payload)
      state.phone = payload
    }
  },
  actions: {}
})
