<template>
  <div>
    <div v-if="second>0" class="result-loading-warp">
      <div class="result-loading circle">
        <circle-progress v-model="percent" />
        <span>{{secondFmt}}s</span>
      </div>
      <div class="result-loading-tips">
        {{i18n.msg_tips_loading}}
      </div>
    </div>
    <div v-else-if="order" class="result-content">
      <img class="unlocked" src="../../assets/imgs/img_release.png" alt="">
      <h2>{{ i18n.title }}</h2>
      <div class="tips">{{ i18n.tips }}</div>
      <img class="mainbanner" :src="imgSrc" alt="">
      <div class="downloadbtn" @click="onDownload"><span>{{ i18n.download }}</span></div>
    </div>
    <result-msg v-else-if="loading" type="100" :text="i18n.msg_result_loading" />
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </div>
</template>

<script>
import CircleProgress from '../../components/result/circle_progress.vue'
import ResultMsg from '../../components/result/msg.vue'
import { ORDER_CHECK } from '../../apis/cabinet.js'
import { PAYPAL_EXC_PAY, QUICKPAY_CONFIRM } from '../../apis/payment.js'
import { mapState } from 'vuex'
let loadingTimer
export default {
  components: {
    ResultMsg,
    CircleProgress
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    paypal (val, oldVal) {
      if (val && val.paymentId) {
        this.onPayExec()
      }
    }
  },
  computed: {
    ...mapState(['browser', 'system']),
    i18n () {
      return this.$t('result')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    isError () {
      return this.second <= 0 && this.error
    },
    percent () {
      return (this.second % 30) * 3.334
    },
    secondFmt () {
      return (this.second + 0.45).toFixed(0)
    },
    imgSrc () {
      if (this.order && this.order.shebei && this.order.number) {
        return `https://m.we-charge.ch/content/cabinet${this.order.shebei}_${this.order.number}.gif`
      } else {
        return require('../../assets/imgs/result_main_banner.png')
      }
    }
  },
  data () {
    return {
      loading: false,
      error: true,
      paypal: null,
      order: null,
      tradeNo: '',
      second: 0,
      payType: 'stripe'
    }
  },
  methods: {
    goRouter (path) {
      this.$router.push(path)
    },
    onDownload () {
      if (this.system === 'ios') {
        window.open(process.env.VUE_APP_APP_STORE)
      } else {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
    },
    fail () {
      this.stopTimer()
      this.order = true
    },
    startTimer () {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer () {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onInput (val) {
      this.$emit('input', val)
    },
    onReload () {
      if (this.tradeNo) {
        this.quickPayConfirm(this.tradeNo, this.payType)
      } else {
        this.onPayExec()
      }
    },
    onPayExec () {
      this.startTimer()
      this.loading = true
      this.$post(
        PAYPAL_EXC_PAY,
        {
          ...this.paypal
        },
        resp => {
          this.loading = false
          this.error = ''
          const data = resp.data
          if (data.out_trade_no) {
            if (data.pay_type === 'CZ') {
              this.$confirm({
                title: this.i18nMsg.title,
                content: this.i18nMsg.success_payment,
                confirmText: this.i18nMsg.confirm,
                showCancel: false
              }).then(() => {
                this.$router.push('/mine')
              })
            } if (data.pay_type === 'YJ') {
              this.checkOrder(data.out_trade_no)
            }
          }
        },
        error => {
          if (this.second < 30) {
            this.onPayExec()
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    checkOrder (tradeNo, payMode = 'stripe') {
      this.startTimer()
      this.tradeNo = tradeNo
      this.loading = true
      // https://m.we-charge.ch/content/cabinet8_1.gif 使用这个图
      this.$get(
        `${ORDER_CHECK}?outTradeNo=${tradeNo}`,
        {},
        resp => {
          this.loading = false
          this.error = ''
          this.stopTimer()
          this.order = resp
        },
        (error, code) => {
          if (code === 1) {
            setTimeout(() => {
              this.checkOrder(tradeNo, payMode)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            let errstr = ''
            if (error.includes('正式订单')) {
              errstr = this.i18n.haveorder
            } else {
              errstr = error
            }
            this.error = errstr
            console.log(error)
          }
        }
      )
    },
    quickPayConfirm (tradeNo, payType = 'YJ') {
      this.startTimer()
      this.tradeNo = tradeNo
      this.$get(
        QUICKPAY_CONFIRM,
        {
          outTradeNo: tradeNo
        },
        resp => {
          setTimeout(() => {
            this.error = ''
            this.stopTimer()
            this.order = resp
            if (resp.data.type === 'CZ') {
              this.$router.replace('/wallet')
            }
            if (resp.data.type === 'FK') {
              this.$router.replace('/order')
            }
          }, 3000)
        },
        (error, code) => {
          if (code === 1) {
            setTimeout(() => {
              this.quickPayConfirm(tradeNo)
            }, 1000)
          } else {
            this.stopTimer()
            this.error = error
            console.log(error)
          }
        }
      )
    },
    onHome () {
      this.$router.replace('/')
    }
  },
  created () {
    console.log('history', window.history)
    console.log('result route:', this.$route.query)
    console.log('url: ', window.location.href)
    let { paymentId, token, PayerID, outTradeNo, payType } = this.$route.query
    console.log('outTradeNo: ', outTradeNo)
    this.payType = payType
    this.payMode = payType
    if (outTradeNo) {
      this.checkOrder(outTradeNo, payType)
    } else {
      this.paypal = {
        paymentId,
        token,
        payerId: PayerID
      }
    }
  }
  // mounted () {
  //   let ael = document.createElement('a')
  //   ael.href = ''
  //   ael.click()
  //   console.log('挂载', ael)
  //   if (window.history && window.history.pushState) {
  //     history.pushState(null, null, document.URL)
  //     window.addEventListener('popstate', this.onHome, false)
  //   }
  // },
  // destroyed () {
  //   window.removeEventListener('popstate', this.onHome, false)
  // }
}
</script>

<style lang="less">
.result-content{
  // padding: 40px;
  min-height: 100vh;
  background: #fff;
  .unlocked{
    max-width: 30%;
    display: block;
    margin: 0px auto;
    padding: 50px 0 0;
  }
  h2{
    font-size: 52px;
    font-family: 'Montserrat-SemiBold';
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 40px 0;
  }
  .mainbanner{
    max-width: 50%;
    display: block;
    margin: 0 auto 60px;
  }
  .tips{
    font-size: 28px;
    color: #666666;
    text-align: center;
    margin: 20px 40px 40px;
  }
  .downloadbtn{
    width: 80%;
    margin: 0 auto;
    background-color: #232360;
    color: #fff;
    font-family: 'DIN Condensed Bold';
    font-size: 60px;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
    border-radius: 20px;
    span{
      display: block;
      height: 56px;
    }
  }
}
.result-loading{
  &.circle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-warp{
    background: #FFFFFF;
    padding: 30px 0;
  }
  & span{
    position: absolute;
    font-size: 70px;
    color: #F13453;
  }
  &-tips{
    text-align: center;
    font-size: 28px;
    color: #00AA80;
  }
}
</style>
