export default {
  home: {
    minute: 'Minute',
    分钟: 'Minute',
    小时: 'Heure',
    天: 'Jour',
    price_info: '{mianfei} minutes gratuites, maximum par jour, {currencyName}{fengding}, dépôt {currencyName}{yajin}, dépôt de location gratuit.',
    feature_1: 'Conception spéciale pour la recharge sans fil.',
    feature_2: 'Emprunt et remboursement universels nationaux.',
    feature_3: 'Quatre modes de charge, adaptés à tous les téléphones portables.',
    submit: 'Recharger',
    borrow: 'Emprunter',
    inputcodetitle: 'Veuillez saisir le numéro de l’appareil sur la station.',
    msg_rentcount: 'Vous avez actuellement {zujieNum} commandes en cours non clôturées.',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    msg_fail_title: 'Erreur',
    msg_fail_content: 'La location a échoué, veuillez réessayer.',
    shoptime: 'Horaires d\'ouverture.',
    supportrental: 'Prend en charge la location.',
    supportreturn: 'Prend en charge le retour.',
    useragreen: 'Cliquez pour accepter le "Formulaire d\'autorisation de débit délégué", "Accord utilisateur", "Accord de recharge".',
    privacyagreen: 'Cliquez pour accepter l\'accord de confidentialité.',
    offline: 'L\'appareil {devicecode} est hors ligne, veuillez contacter le personnel sur place.',
    permissionfail: 'Autorisation refusée.',
    unbound: 'Le code QR mixte {devicecode} n\'est pas lié à l\'appareil.',
    nobattery: 'Aucune batterie disponible dans l\'appareil {devicecode}.',
    locate: 'Localiser une station We-Charge.',
    battGo: 'Scanner',
    station: 'Le QR code',
    scan: 'Charger en déplacement',
    the: 'Retourner partout',
    QR: 'Trouvez votre station We-Charge la plus proche.',
    code: 'code',
    charge: 'Charge',
    onthego: 'en route',
    return: 'Retour',
    anywhere: 'n\'importe où',
    step1: 'Trouvez la borne de recharge we-charge la plus proche',
    step2: 'Scannez le QR code sur la station avec l\'application We-Charge sur votre téléphone pour déverrouiller votre batterie externe We-Charge.',
    step3: 'Prenez la batterie déverrouillée depuis la station et chargez vos appareils où que vous soyez !',
    step4: 'Remettez la batterie externe dans n\'importe quelle station la plus proche, à votre convenance.'
  },
  loginborrow: {
    powerbanktitle: 'Déverrouillez votre batterie externe We-Charge.',
    msg_fail_title: 'Erreur',
    msg_fail_user: 'Impossible de récupérer les informations utilisateur. Voulez-vous réessayer ?',
    msg_rentcount: 'Vous avez actuellement {zujieNum} commandes en cours non clôturées.',
    msg_fail_support: 'Le système n\'est pas pris en charge.',
    msg_fail_confirm: 'Confirmer',
    msg_fail_retry: 'Réessayer',
    msg_fail_cancel: 'Annuler',
    msg_fail_order: 'Échec de la création de la commande de location.',
    msg_fail_paymod: 'Échec de l\'obtention de la méthode de paiement.',
    msg_fail_login: 'Connexion échouée.',
    Apple: 'Apple.',
    otheropt: 'Autres options.',
    selectmethod: 'Sélectionnez votre méthode de paiement.',
    paymentdetail: 'Détails de la méthode de paiement.',
    couponcode: 'Code promotionnel.',
    rent: 'Confirmer le paiement.',
    pricing: 'Voir les tarifs',
    phone: 'Téléphone.',
    Google: 'Google.',
    Facebook: 'Facebook.',
    loginfb: 'Se connecter avec Facebook.',
    logingg: 'Se connecter avec Google.',
    captcha_code: 'Code.',
    phonenumber: 'Entrez votre numéro de téléphone.',
    msg_fail_code: 'Veuillez entrer le code de vérification.',
    msg_fail_phone: 'Veuillez entrer votre numéro de téléphone.',
    logout: 'Quitter le compte.',
    savenow: 'Enregistrer maintenant.',
    delete: 'Supprimer.',
    addcard: 'Entrer les détails de la carte.',
    msg_fail_content: 'Location échouée, veuillez réessayer.',
    title: 'Veuillez confirmer l\'autorisation.',
    policy: 'Politique.',
    term: 'Conditions générales.',
    and: 'et',
    privacy: 'Politique de confidentialité.',
    tips: 'En continuant, vous acceptez nos termes et conditions ainsi que notre politique de confidentialité.',
    cancel: 'ANNULER',
    agree: 'ACCEPTER'
  },
  preauth: {
    preauthortitle: 'À propos de l\'autorisation préalable',
    tip1: 'Lors du début d\'une location, une pré-autorisation est placée sur votre méthode de paiement. Cela sert uniquement à garantir que des fonds suffisants sont disponibles pour payer votre location.',
    tip2: 'Votre banque peut vous envoyer une notification, mais ce n\'est pas un débit : seul le prix de votre location sera débité.',
    tip3: 'Selon votre banque, cela peut prendre quelques heures ou quelques jours pour que le montant soit ajusté sur vos relevés, ce qui est normal.'
  },
  rentcost: {
    rentcosttitle: 'Quel est le coût de la location ?',
    tip1: 'Si vous ne retournez pas la batterie externe dans les 4 jours, vous serez facturé des frais de non-retour de {currency} {yajin}.',
    tip2: 'La durée est arrondie à la demi-heure la plus proche (30 ou 60 minutes).',
    tip3: 'Une location d\'un jour correspond à 24 heures à partir de l\'heure d\'embauche de la batterie.',
    tip4: 'Certains lieux peuvent proposer des heures gratuites ou des réductions. Profitez-en !',
    Days: 'Jours',
    Hours: 'Heures',
    Minutes: 'Minutes'
  },
  logincode: {
    login: 'Connexion.',
    retry: 'Renvoyer.'
  },
  agreenconfirm: {
    title: 'Veuillez confirmer l\'autorisation.',
    policy: 'Politique.',
    term: 'Conditions générales de We-Charge.',
    and: 'et',
    privacy: 'Politique de confidentialité.',
    tips: 'Je suis d\'accord avec.',
    cancel: 'ANNULER',
    agree: 'ACCEPTER'
  },
  users: {
    username: 'Nom d\'utilisateur.',
    mobile: 'Mobile.',
    login: 'Connexion.',
    orders: 'Commandes de location.',
    wallet: 'Mon portefeuille.',
    joinus: 'Coopération pour rejoindre.',
    langsetting: 'Paramètres de langue.'
  },
  login: {
    phonenumber: 'Numéro de téléphone.',
    verificationcode: 'Code de vérification.',
    Rentpowerbank: 'Louez une batterie externe.',
    Rentpowerbanktip: 'Entrez votre numéro de téléphone portable pour commencer.',
    phone_number: 'Veuillez entrer un numéro mobile à {0} chiffres.',
    captcha_code: 'Code.',
    send_code: 'Envoyer le code.',
    login: 'Connexion.',
    agree: 'En cliquant sur Connexion, vous acceptez :',
    readme: 'Accord utilisateur.',
    msg_fail_login: 'Connexion échouée.',
    msg_success_login: 'Connexion réussie.',
    msg_success_login_code: 'Reçu avec succès, veuillez télécharger l\'application pour vous connecter et l\'utiliser.',
    msg_fail_user: 'Impossible de récupérer les informations utilisateur. Voulez-vous réessayer ?',
    no_sms: 'Pas reçu de SMS ?',
    retry: 'Renvoyer.',
    receive: 'Appliquer.',
    invite_pwd: 'Code d\'invitation.',
    vip_agreen_tip: 'En continuant, vous acceptez nos :',
    vip_agreen_service: 'Conditions de service.',
    vip_agreen_and: 'et',
    vip_agreen_policy: 'Politique de confidentialité.'
  },
  confirm: {
    title: 'Confirmation du paiement',
    pay_label: 'Montant.',
    addcard: 'Ajouter une carte au portefeuille.',
    price_tips: 'Cliquez pour confirmer le paiement, ce qui indique que vous avez lu et accepté le.',
    price_btn: '《Accord de valeur ajoutée》.',
    pay_type: 'Veuillez sélectionner votre méthode de paiement préférée.',
    submit: 'Confirmer la location.',
    charge: 'Confirmer la recharge.',
    msg_fail_title: 'Avertissement',
    msg_fail_order: 'Échec de la création de la commande de location.',
    msg_fail_confirm: 'Confirmer',
    msg_fail_retry: 'Réessayer',
    msg_fail_cancel: 'Annuler'
  },
  join: {
    title: 'Partenariat',
    call: 'Appel.',
    tips: 'Veuillez fournir vos informations, nous vous contacterons sous peu !',
    type1: 'Installation',
    type2: 'Publicité',
    type3: 'Autre',
    shop_name: 'Nom du commerçant.',
    contact_person: 'Personne de contact.',
    phone: 'Numéro de contact.',
    submit: 'Soumission.',
    noempty: 'Ne peut pas être vide.'
  },
  mine: {
    title: 'Profil utilisateur',
    wallet: 'Mon portefeuille.',
    balance: 'Mon solde.',
    mywalletcard: 'Ma carte.',
    addcard: 'Ajouter une carte au portefeuille.',
    deposit: 'Dépôt.',
    wallet_in: 'Recharger.',
    wallet_out: 'Retirer.',
    wallet_log: 'Historique du portefeuille.',
    order: 'Commande.',
    login_tips: 'Veuillez vous connecter.',
    msg_fail_title: 'Erreur',
    msg_fail_user: 'Impossible de récupérer les informations utilisateur. Voulez-vous réessayer ?',
    help: 'Aide.',
    setting: 'Paramètres.',
    logout: 'Déconnexion.',
    unpaid: 'Non payé.',
    using: 'En cours d\'utilisation.'
  },
  msg: {
    none_code: 'Le code de \'appareil n\'existe pas.',
    sms_fail: 'Échec de \'envoi du SMS.',
    unlogin: 'Vous \'êtes pas encore connecté, veuillez vous connecter.',
    msg_fail_title: 'Avertissement',
    deletecard: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
    loading: 'Chargement...',
    title: 'Conseils',
    success: 'Opération réussie.',
    error: 'Erreur inconnue, veuillez réessayer plus tard.',
    roleerror: 'Autorisations insuffisantes, veuillez consulter l\'administrateur.',
    neterror: 'Échec de la connexion réseau. Veuillez réessayer plus tard.',
    confirm: 'Confirmer',
    retry: 'Réessayer',
    cancel: 'Annuler',
    msg_logout: 'Êtes-vous sûr de vouloir quitter le compte ?',
    vieworder: 'Voir la commande.',
    addorselectcard: 'Veuillez ajouter ou sélectionner un portefeuille pour continuer.',
    success_payment: 'Paiement effectué.',
    fail_payment: 'Échec du paiement.',
    keyword: 'Mot-clé',
    unpaid_order: 'Il y a des commandes impayées.',
    go_pay: 'Aller payer.',
    no_more: 'Plus rien.',
    noshop: 'Aucun commerçant.',
    msg_fail_code: 'Veuillez entrer le code de vérification.',
    msg_fail_phone: 'Veuillez entrer votre numéro de téléphone.',
    exceedorder: 'Vous avez déjà une commande en cours de location, veuillez la retourner avant de continuer.'
  },
  result: {
    title: 'Location confirmée.',
    tips: 'Pour voir les commandes et d\'autres emplacements We-Charge, veuillez télécharger l\'application.',
    download: 'TÉLÉCHARGER',
    msg_result_loading: 'Le résultat du paiement est en cours de confirmation...',
    msg_tips_loading: 'La batterie portable est en train d\'être éjectée, veuillez patienter...',
    haveorder: 'Une commande officielle a été générée.'
  },
  result_msg: {
    loading: 'Chargement...',
    refresh: 'Rafraîchir.'
  },
  order: {
    title: 'Commande de location.',
    in_use: 'En cours.',
    ended: 'Terminée.',
    msg_error: 'Demande échouée.',
    no_data: 'Pas de données.'
  },
  order_item: {
    order_number: 'Non.',
    venue_rented: 'Lieu loué.',
    time_rented: 'Heure de location.',
    venue_returned: 'Lieu de retour.',
    time_returned: 'Heure de retour.',
    charge_standard: 'Standard de charge.',
    rent_occurred: 'Loué.',
    returned: 'Retourné.',
    unreturned: 'Non retourné.',
    type_0: 'Non terminé.',
    type_1: 'En location.',
    type_2: 'Annulée.',
    type_3: 'Retournée.',
    type_4: 'Défaillance survenue.',
    type_5: 'Temps dépassé.',
    min: 'Min.',
    unpaid: 'Non payé.',
    payment: 'Paiement.'
  },
  setting: {
    title: 'Paramètres.',
    lang: 'Langue.'
  },
  help: {
    title: 'FAQ.'
  },
  wallet_in: {
    title: 'Ajouter une valeur.',
    price_label: 'Veuillez sélectionner un autre montant de recharge.',
    price_placeholder: 'Veuillez entrer le montant de la recharge.',
    balance: 'Solde.',
    chargemoney: 'Veuillez sélectionner le montant de la recharge.',
    submit: 'Soumettre.',
    loading: 'Chargement...',
    msg_title: 'Rappel.'
  },
  wallet_out: {
    title: 'Retrait.',
    price_label: 'Veuillez entrer le montant du retrait.',
    price_placeholder: 'Veuillez entrer le montant du retrait.',
    balance: 'Solde actuel.',
    balance_all: 'Retirer tout.',
    deposit: 'Dépôt.',
    deposit_tips: 'Il existe une commande en cours. Le dépôt de {pcurrencyName}{pyajin} est non remboursable.',
    submit: 'Soumettre.',
    apply: 'Appliquer.',
    confirm: 'Confirmer.',
    loading: 'Chargement...',
    msg_fail_price: 'Le montant du retrait est incorrect.',
    msg_fail_balance: 'Le montant du retrait ne peut pas être supérieur au solde actuel.',
    msg_title: 'Erreur',
    msg_success_content: 'Demande de remboursement réussie.',
    tips_title: 'Explication',
    btn: 'Soumettre',
    error_tips: 'Veuillez entrer des informations complètes.n'
  }
}
