export default {
  home: {
    minute: 'Minuten',
    分钟: 'Minute',
    小时: 'Stunde',
    天: 'Tag',
    price_info: '{mianfei} Minuten frei, maximal pro Tag {currencyName}{fengding}, Kaution {currencyName}{yajin}, kostenlose Mietkaution',
    feature_1: 'Spezielles Design für kabelloses Laden',
    feature_2: 'Landesweiter Verleih und Landesweite Rückgabe',
    feature_3: 'Vier Lademöglichkeiten, passend für alle Endgeräte',
    submit: 'Laden',
    borrow: 'Ausleihen',
    inputcodetitle: 'Bitte gib die Gerätenummer am Bildschirm ein',
    msg_rentcount: 'Du hast zurzeit {zujieNum} nicht abgeschlossene Ausleihen',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    msg_fail_title: 'Fehler',
    msg_fail_content: 'Die Ausleihe ist fehlgeschlagen, bitte versuche es erneut',
    shoptime: 'Geschäftszeiten',
    supportrental: 'Offene Bezugsmöglichkeiten',
    supportreturn: 'Offene Retournierungsmöglichkeiten',
    useragreen: 'Klicken hier, um dem "Formular zur Erteilung einer Einzugsermächtigung", der "Nutzungsvereinbarung" und der "Aufladevereinbarung" zuzustimmen',
    privacyagreen: 'Klicke hier um der Datenschutzvereinbarung zuzustimmen',
    offline: 'Das Gerät {devicecode} ist offline, bitte kontaktiere die Helpline',
    permissionfail: 'Genehmigung abgelehnt.',
    unbound: 'Der QR-Code {devicecode} ist nicht mit dem Gerät verbunden',
    nobattery: 'Das Gerät {devicecode} hat zurzeit keinen Akku',
    locate: 'Lokalisiere eine',
    battGo: 'we-charge',
    station: 'Station',
    scan: 'Scanne',
    the: 'den',
    QR: 'QR',
    code: 'Code',
    charge: 'Lade',
    onthego: 'unterwegs',
    return: 'Retourniere',
    anywhere: 'Überall',
    step1: 'Finde eine we-charge Station in Deiner Nähe',
    step2: 'Um eine Powerbank zu beziehen, öffne die WeCharge-App und scanne den QR-Code auf der Ladestation.',
    step3: 'Beziehe die freigeschaltete Powerbank und lade nun von unterwegs!',
    step4: 'Retourniere die genutzte Power bank bei einem Standort Deiner Wahl.'
  },
  loginborrow: {
    powerbanktitle: 'Beziehe Deine WeCharge Powerbank',
    msg_fail_title: 'Fehler',
    msg_fail_user: 'Fehler beim Erfassen der Benutzerdaten. Möchtest Du es erneut versuchen?',
    msg_rentcount: 'Du hast zurzeit {zujieNum} nicht abgeschlossene Ausleihen',
    msg_fail_support: 'Dieses System wird nicht unterstützt',
    msg_fail_confirm: 'Bestätigen',
    msg_fail_retry: 'Wiederholen',
    msg_fail_cancel: 'Abbrechen',
    msg_fail_order: 'Bezug fehlgeschlagen',
    msg_fail_paymod: 'Zahlungsmethode konnte nicht hinterlegt werden',
    msg_fail_login: 'Anmeldung fehlgeschlagen',
    Apple: 'Apple',
    otheropt: 'Andere Optionen',
    selectmethod: 'Wähle eine Zahlungsoption aus',
    paymentdetail: 'Details zur Zahlungsmethode',
    couponcode: 'Gutscheincode',
    rent: 'Bestätige die Bezahlung',
    pricing: 'Preiseinsicht',
    phone: 'Telefon',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Logge Dich mit Facebook ein',
    logingg: 'Logge Dich mit Google ein',
    captcha_code: 'Code',
    phonenumber: 'Gib Deine Telefonnummer ein',
    msg_fail_code: 'Bitte gib Deinen Bestätigungscode ein',
    msg_fail_phone: 'Bitte gib Deine Telefonnummer ein',
    logout: 'Verlasse den Account',
    savenow: 'Jetzt speichern',
    delete: 'Löschen',
    addcard: 'Gib Deine Kartendaten ein',
    msg_fail_content: 'Ausleihe fehlgeschlagen, bitte versuche es erneut',
    title: 'Bitte bestätige die Authorisierung',
    policy: 'Policy.',
    term: ' AGB\'s',
    and: 'und',
    privacy: 'Datenschutzbestimmungen.',
    tips: 'Mit der Fortsetzung bestätigst Du unsere ',
    cancel: 'ABBRECHEN',
    agree: 'BESTÄTIGEN'
  },
  preauth: {
    preauthortitle: 'Bitte bestätigen Sie die Berechtigung',
    tip1: 'Bei Beginn einer Anmietung wird eine Vorautorisierung auf Ihre Zahlungsmethode gesetzt. Damit soll sichergestellt werden, dass genügend Mittel für die Bezahlung Ihrer Miete zur Verfügung stehen.',
    tip2: 'Ihre Bank schickt Ihnen möglicherweise eine Benachrichtigung, die aber keine Kosten verursacht: Es wird nur der Preis für die Miete berechnet.',
    tip3: 'Je nach Bank kann es ein paar Stunden bis zu ein paar Tagen dauern, bis der Betrag auf Ihren Kontoauszügen angepasst wird.'
  },
  rentcost: {
    rentcosttitle: 'Wie viel kostet der Verleih?',
    tip1: 'Wenn Sie die Powerbank nicht innerhalb von 4 Tage zurückgeben, wird Ihnen eine Gebühr von {currency} {yajin} verrechnet.',
    tip2: 'Die Dauer wird auf die nächste halbe Stunde (30 oder 60 Minuten) gerundet.',
    tip3: 'Die Tagesmiete beträgt 24 Stunden ab dem Zeitpunkt, an dem die Powerbank ausgeliehen wird.',
    tip4: 'Einige Standorte bieten möglicherweise kostenlose Stunden oder Ermäßigungen an. Viel Spaß!',
    Days: 'Tage',
    Hours: 'Stunden',
    Minutes: 'Minuten'
  },
  logincode: {
    login: 'Anmelden',
    retry: 'Erneut senden'
  },
  agreenconfirm: {
    title: 'Bitte bestätigen Sie die Berechtigung',
    policy: 'Policy.',
    term: 'Allgemeine Geschäftsbedingungen der WeCharge',
    and: 'und',
    privacy: 'Datenschutz.',
    tips: 'Ich bin damit einverstanden ',
    cancel: 'ABBRUCH',
    agree: 'BESTÄTIGUNG'
  },
  login: {
    phonenumber: 'Handynummer',
    verificationcode: 'Verifizierungscode',
    Rentpowerbank: 'Powerbank mieten',
    Rentpowerbanktip: 'Geben Sie Ihre Handynummer ein, um loszulegen',
    phone_number: 'Bitte geben Sie eine {0}-stellige Handynummer ein ',
    captcha_code: 'Code',
    send_code: 'Code senden',
    login: 'Anmelden',
    agree: 'Indem Sie auf "Anmelden" klicken, erklären Sie sich mit "einverstanden ',
    readme: 'Benutzervereinbarung',
    msg_fail_login: 'Anmeldung fehlgeschlagen',
    msg_success_login: 'Anmeldung erfolgreich',
    msg_success_login_code: 'Erfolgreiche Übermittlung, bitte laden Sie die APP herunter, um sich anzumelden',
    msg_fail_user: 'Fehler beim Abrufen von Benutzerinformationen. Möchten Sie es erneut versuchen?',
    no_sms: 'Ich habe keine Textnachricht',
    retry: 'Erneut senden',
    receive: 'Anwenden',
    invite_pwd: 'Einladungscode',
    vip_agreen_tip: 'Indem Sie fortfahren, erklären Sie, dass Sie unser akzeptieren',
    vip_agreen_service: 'Allgemeine Geschäftsbedingungen',
    vip_agreen_and: 'und',
    vip_agreen_policy: 'Datenschutzbestimmungen'
  },
  confirm: {
    title: 'Zahlungsbestätigung',
    pay_label: 'Betrag',
    addcard: 'Add Wallet',
    price_tips: 'Klicken Sie, um die Zahlung zu bestätigen und anzuzeigen, dass Sie die gelesen und akzeptiert haben ',
    price_btn: '《Value-added Agreement》',
    pay_type: 'Bitte wählen Sie Ihre bevorzugte Zahlungsmethode aus',
    submit: 'Miete Bestätigen',
    charge: 'Aufladung bestätigen',
    msg_fail_title: 'Warnung',
    msg_fail_order: 'Fehlgeschlagen! Powerbank konnte nicht gemietet werden',
    msg_fail_confirm: 'Bestätigen',
    msg_fail_retry: 'Wiederholen',
    msg_fail_cancel: 'Abbrechen'
  },
  msg: {
    none_code: 'Diese Gerätenummer existiert nicht',
    sms_fail: 'SMS konnte nicht zugestellt werden',
    unlogin: 'Du bist zurzeit nicht eingeloggt, bitte logge Dich ein',
    msg_fail_title: 'Achtung',
    deletecard: 'Sind Sie sicher, dass Sie diese Karte löschen wollen?',
    loading: 'Laden...',
    title: 'Tipps',
    success: 'Erfolgreiche Operation',
    error: 'Unbekannter Fehler, bitte versuchen Sie es später noch einmal.',
    roleerror: 'Unzureichende Berechtigungen, bitte wenden Sie sich an den Administrator',
    neterror: 'Netzwerkverbindung fehlgeschlagen. Bitte versuchen Sie es später erneut',
    confirm: 'Bestätigen Sie',
    retry: 'Wiederholen',
    cancel: 'Abbrechen',
    msg_logout: 'Sind Sie sicher, dass Sie das Konto verlassen wollen?',
    vieworder: 'Bestellungsverlauf ansehen',
    addorselectcard: 'Bitte fügen Sie ein Kartenpaket hinzu oder wählen Sie eines aus, um fortzufahren',
    success_payment: 'Zahlung abgeschlossen',
    fail_payment: 'Zahlungsausfall',
    keyword: 'Schlüsselwort',
    unpaid_order: 'Es liegen unbezahlte Verleihungen vor',
    go_pay: 'Bezahlen',
    no_more: 'nicht mehr',
    noshop: 'Keine Händler',
    msg_fail_code: 'Bitte geben Sie den Verifizierungscode ein',
    msg_fail_phone: 'Bitte geben Sie Ihre Telefonnummer ein',
    exceedorder: 'Sie haben bereits eine Powerbank, bitte geben Sie diese zurück, bevor Sie fortfahren'
  },
  result: {
    title: 'Ausleihe bestätigt',
    tips: 'Um Deine vergangenen Ausleihungen und alle We-Charge Standorte einzusehen, lade unsere App herunter.',
    download: 'Herunterladen',
    msg_result_loading: 'Die Zahlung wird bestätigt...',
    msg_tips_loading: 'Die Powerbank wird gleich freigeschaltet, wir bitten Sie um etwas Geduld...',
    haveorder: 'Bestellung abgeschlossen.'
  },
  result_msg: {
    loading: 'Laden...',
    refresh: 'Aktualisieren'
  }
}
